import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import {
  AddToCart,
  Layout,
  Grid,
  ProductHeader,
  IngredientsBanner,
  ReviewsBanner,
  OtherProductCell,
  VarietyPackBanner,
  MidBanner,
} from '@components'
import { WithData, rgbaColorFromKey } from '@utilities'
import { typeStyle } from '@theme'

interface ProductProps {
  data: GatsbyTypes.productQuery
}

export const findNextProduct = (
  thisProductSlug: string,
  allProducts: Array<Partial<GatsbyTypes.SanityProduct>>
) => {
  const filteredProducts = allProducts.filter(product => product.type !== "accessory");

  const sortedProducts = filteredProducts.sort((a, b) =>
    a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0
  )
  const currentProductIndex = allProducts
    .map((p) => p.slug!.current!)
    .indexOf(thisProductSlug)
  const nextProductIndex =
    currentProductIndex === allProducts.length - 1 ? 0 : currentProductIndex + 1
  return sortedProducts[nextProductIndex]
}

const ProductPage = ({
  data: {
    sanityProduct,
    otherSanityProducts,
    allShopifyProduct,
    sanitySiteSettings,
  },
}: ProductProps) => {
  if (
    !sanityProduct ||
    !otherSanityProducts ||
    !allShopifyProduct ||
    !sanitySiteSettings
  )
    return <></>
  const {
    slug,
    title,
    summary,
    description,
    creativeIngredients,
    longIngredients,
    reviewsAndPix,
    centerReviewImage,
    canImage,
    canImageWet,
    accentSticker,
    accentSticker2,
    bannerImage,
    mainColor,
    mainColorForeground,
    altColor,
    altColorForeground,
    nutritionFactsImage,
  } = sanityProduct
  const findProductVariant = (handle?: string) => {
    const d = allShopifyProduct.nodes.find((p) => p.handle === handle)
    return d?.variants?.[0]
  }

  const nextProduct = findNextProduct(
    slug!.current!,
    otherSanityProducts.nodes as Array<Partial<GatsbyTypes.SanityProduct>>
  )

  return (
    <Layout>
      <Helmet>
        <title>Bawi Agua Fresca: {title}</title>
      </Helmet>
      <Grid>
        <ProductHeader
          canImage={canImageWet ? canImageWet : canImage}
          {...{
            title,
            summary,
            description,
            slug,
            mainColor,
            mainColorForeground,
            altColor,
            accentSticker,
            accentSticker2,
            nextProduct,
          }}
        >
          <StyledAddToCart
            variant={findProductVariant(slug?.current)}
            {...{ altColor }}
            priceColor={altColor}
          />
        </ProductHeader>
        <IngredientsBanner
          {...{
            creativeIngredients,
            longIngredients,
            mainColor,
            mainColorForeground,
            nutritionFactsImage,
          }}
        />
        <MidBanner image={bannerImage?.asset?.url} />
        {reviewsAndPix && reviewsAndPix.length > 0 && (
          <ReviewsBanner
            {...{
              reviewsAndPix,
              mainColor,
              mainColorForeground,
              altColor,
              centerReviewImage,
            }}
          />
        )}
        {Array.from(otherSanityProducts?.nodes)
          .filter(
            (p) => p.type === 'flavor' && p.slug?.current !== slug?.current
          )
          .map(
            (otherProduct) =>
              !!otherProduct && (
                <OtherProductCell
                  product={otherProduct as Partial<GatsbyTypes.SanityProduct>}
                  key={otherProduct._id}
                  variant={
                    findProductVariant(
                      otherProduct.slug?.current
                    ) as GatsbyTypes.ShopifyProductVariant
                  }
                />
              )
          )}
        {Array.from(otherSanityProducts?.nodes)
          .filter((p) => p.type === 'variety')
          .map(
            (otherProduct) =>
              !!otherProduct && (
                <VarietyPackBanner
                  product={otherProduct as Partial<GatsbyTypes.SanityProduct>}
                  key={otherProduct._key}
                />
              )
          )}
      </Grid>
    </Layout>
  )
}

const mapDataToProps = (data: GatsbyTypes.productQuery) => ({ ...data })

export const query = graphql`
  query product($slug: String!) {
    sanitySiteSettings {
      ...siteSettingsFields
    }
    sanityProduct(slug: { current: { eq: $slug } }) {
      ...productFields
    }
    otherSanityProducts: allSanityProduct(filter: { active: { eq: true } }) {
      nodes {
        _id
        _key
        slug {
          current
        }
        type
        title
        canImage {
          asset {
            url
          }
        }
        canImageWet {
          asset {
            url
          }
        }
        accentSticker
        mainColor
        mainColorForeground
        altColor
        altColorForeground
        summary {
          _rawSpanish
          _rawSpanglish
          _rawEnglish
        }
      }
    }
    allShopifyProduct {
      nodes {
        title
        handle
        variants {
          price
          storefrontId
          inventoryQuantity
          title
        }
      }
    }
  }
`

const StyledAddToCart = styled((props) => <AddToCart {...props} />)<{
  altColor?: string
}>`
  @media only screen and (max-width: 743px) {
    > * {
      padding: 25px;
      grid-column-end: span 1;
    }
    div:nth-of-type(2) {
      display: flex;
      flex-direction: column-reverse;
      ${typeStyle.footnote}
      font-size: 20px;
      color: ${(props) => rgbaColorFromKey(props.altColor, 1)};
      > div {
        margin-bottom: 1em;
      }
    }
  }
`

export default WithData(mapDataToProps, ProductPage)
